.btn {
  padding-bottom: $btn-padding-y * 0.75;

  .fa {
    &:first-child {
      margin-right: ($spacer-x / 2); 
    }
  }
}

.btn-sm {
  font-size: $font-size-sm;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-sm;
}

.btn-xs {
  font-size: $font-size-xs;
  padding: 0.375rem 0.625rem 0.25rem;
}

.btn-lg {
  font-size: $font-size-base;
  line-height: $line-height;
}

.btn-lg,
.btn-group-lg > .btn {
  padding-bottom: $btn-padding-y-lg * 0.85;
}

//.btn-secondary,
.btn-secondary-outline {
  @include hover {
    color: $btn-secondary-color;
    background-color: $white;
    border-color: $btn-secondary-border;
  }

  &:focus,
  &.focus {
    color: $btn-secondary-color;
    background-color: $white;
    border-color: $btn-secondary-border;
  }
}

.btn-secondary-outline {
  background-color: transparent;
  color: $gray-dark;
  border-color: $gray-dark;

  @include hover {
    background-color: $white;
    border-color: $gray-dark;
    color: $gray-dark;
  }

  &:focus,
  &.focus {
    background-color: $white;
    border-color: $theme-icon;
    color: $header-text;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $white !important;
    background-color: $gray-dark;
    border-color: $gray-dark;

    @include hover-focus {
      color: #fff;
      background-color: $gray-dark;
      border-color: $gray-dark;
    }
  }
}

.btn-inverse {
  background-color: $brand-inverse;
  border-color: $brand-inverse;
  color: $white;
  
  @include hover {
    background-color: darken($brand-inverse, 5%);
    border-color: darken($brand-inverse, 5%);
    color: $white;
  }
  
  &:focus,
  &.focus {
    background-color: darken($brand-inverse, 5%);
    border-color: darken($brand-inverse, 5%);
    color: $white;
  }
}

.btn-default {
  @extend .btn-secondary;
}

.btn-group-actions {
    
    > .btn {
        background-color: transparent;
        color: #b0bec5;
        
        width: 32px;
        height: 24px;
        padding: 0;
    
        &::before {
            display: block;
            content: '\2022\2022\2022';
            line-height: 1.1;
            font-size: 24px;
        }
    
        .fa {
            display: none;
            margin-right: 0;
            font-size: 24px;
        }
    
        &::after {
            content: initial;
        }
        
    }
}
.btn-close {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 2rem;
  padding: 0 1rem;
}