.input-group-addon {
  .fa {
    color: #8C97B2;
    padding-top: .175em;
  }
}

.input-group-addon:first-child {
  border-right: $input-btn-border-width solid $input-group-addon-border-color;
}

.input-group-addon:last-child {
  border-left: $input-btn-border-width solid $input-group-addon-border-color;
}

.form-check-input {
  margin-right: $spacer-x / 4;
}

.form-main {
  background-color: #fff;
  position: relative;
  padding: ($spacer-y * 2) 0;
  margin: -24px -24px 0;

  /*&:before {
    background-color: $white;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(23.6% + .75rem);
    height: 100%;
    padding: .375rem .75rem;
    /*box-shadow: inset -4px 0 3px rgba(#8C97B2, .05);*!/
    visibility: hidden;
  }*/

  .form-group {
    position: relative;

    &.row {
      align-items: flex-start;
    }
  }

  fieldset {
    margin-bottom: ($spacer-y * 2);
  }

  .form-group-control {
    padding-left: ($spacer-x * 2);
    padding-right: ($spacer-x * 2);
  }

  .form-control-label {
    $padding-x: $spacer-x * 2;
    color: #686868;
    padding-left: $padding-x;
    padding-right: $padding-x;
    font-weight: normal;
    font-size: $font-size-sm;
  }

  .custom-checkbox-type2 {
    width: 120px;
    input[type="checkbox"]:disabled {
      visibility: hidden;
    }
  }
}

.form-main-group-title {
  margin-top: $spacer-y;
}

.form-group-actions {
  padding-top: $spacer-y;

  .btn + .btn {
    margin-left: $spacer-x;
  }
}

.form-labels-mask {
  display: none;
}

.btn-field {
  .btn {
    &:last-child {
      margin-left: 20px;
    }
  }
}



.filter-control {
  label {
    margin-bottom: 0.5rem !important;
    color: #686868;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 12px;
  }

  select {
    height: 35px;
  }
}

.form-group-file {
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    padding: 1px 1px 1px 0;
    font-size: $font-size-base;
    line-height: $line-height;
    //color: #8C97B2;
    color: $input-color-placeholder;
    background: $input-bg;
    border: $input-btn-border-width solid $input-border-color;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
    @include border-radius($input-border-radius);
    @include box-shadow($input-box-shadow);
    @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

    * {
      pointer-events: none;
    }

    span {
      display: inline-block;
      flex: 1;
      font-size: $font-size-base;
      padding: ($input-padding-y - 0.0625) $input-padding-x;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::after {
      background-color: #DADFEA;
      display: inline-block;
      color: #fff;
      content: attr(data-button-label);
      font-size: $font-size-xs;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      padding:  ($input-padding-y + 0.125) $btn-padding-x;
      @include border-radius($input-border-radius);
    }
  }

  input:focus + label,
  input.has-focus + label {
    border-color: $input-border-focus;
  }

  input:disabled + label,
  input[readonly] + label {
    background-color: $input-bg-disabled;
  }

  input:disabled + label {
    cursor: $cursor-disabled;
  }

  input + label:hover {
    &::after {
      background-color: #8C97B2;
    }
  }
}

.form-control-failed {
  border-color: $brand-danger;

  &.selectized + .selectize-control {
    .selectize-input {
      border-color: $brand-danger;
    }
  }
}

.form-float-label {
  $input-border-width: strip-units($input-btn-border-width) / strip-units($font-size-root);
  $placeholder-padding-y: $input-padding-y + $input-border-width;
  $placeholder-padding-x: $input-padding-x + $input-border-width;

  position: relative;

  label,
  .fa {
    background-color: $input-bg;
    color: $input-color-placeholder;
    display: inline-block;
    line-height: $input-line-height;
    position: absolute;
    top: $input-btn-border-width;
  }

  label {
    padding: $placeholder-padding-y $placeholder-padding-x 0;
    font-size: $font-size-xs;
    margin-bottom: 0;
    right: $input-btn-border-width;
    left: $input-btn-border-width;
    font-family: $font-family-maison-mono;
    @include border-radius($input-border-radius  $input-border-radius 0 0);
  }

  .fa {
    $icon-padding-y: $placeholder-padding-y + ($font-size-xs/2) + $input-border-width;

    padding: $icon-padding-y $input-padding-x;
    font-size: $font-size-base;
    right: $input-btn-border-width;
    @include border-radius(0 $input-border-radius  $input-border-radius 0);
  }

  .form-control {
    padding-top: ($input-padding-y * 1.5) + $font-size-xs;
  }

  &.has-icon {
    .form-control {
      padding-right: $font-size-base + ($input-padding-x * 2);
    }
  }

  &.is-file {
    $input-btn-padding-y: (($input-padding-y * 1.5) + ($font-size-xs / 2)) - ($input-border-width / 2);

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      top: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      background-color: transparent;
      padding-bottom: $input-height - $input-padding-y - ($input-border-width * 2);
      z-index: 2;

      &::after {
        background-color: #DADFEA;
        cursor: pointer;
        display: inline-block;
        color: #fff;
        content: attr(data-button-label);
        font-size: $font-size-xs;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        padding: $input-btn-padding-y $btn-padding-x;
        position: absolute;
        right: $input-btn-border-width;
        top: $input-btn-border-width;
        @include border-radius($input-border-radius);
      }
    }

    input:not(:disabled) + label:hover {
      &::after {
        background-color: #8C97B2;
      }
    }

    input:disabled + label {
      &::after {
        cursor: $cursor-disabled;
      }
    }

    &::after {
      content: attr(data-value);
      display: block;
      width: 100%;
      padding: (($input-padding-y * 1.5) + $font-size-xs) $input-padding-x $input-padding-y;
      font-size: $font-size-base;
      line-height: $input-line-height;
      color: $input-color;
      background-color: $input-bg;
      background-image: none;
      background-clip: padding-box;
      border: $input-btn-border-width solid $input-border-color;
      min-height: ($input-height - $input-padding-y) + ($input-padding-y * 1.5) + $font-size-xs - ($input-border-width * 2);
      @include border-radius($input-border-radius);
      @include box-shadow($input-box-shadow);
      @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
    }

    &.is-disabled::after {
      background-color: $input-bg-disabled;
    }

    &.has-focus::after {
      border-color: $input-border-focus;
    }
  }
}


$input-switch-bg: $input-border-color;
$input-switch-active-bg: $brand-success;
$input-switch-height: 18px;
$input-switch-width: 42px;
$input-switch-border-width: $input-btn-border-width;
$input-switch-border-radius: $input-switch-height/2;
$input-switch-shadow-hue: hue($input-border-color);
$input-switch-handle-bg: $input-bg;
$input-switch-handle-size: $input-switch-height - ($input-btn-border-width * 2);

.form-control-switch {
  input {
    opacity: 0;
    position: absolute;
    left: -9999px;

    &:checked {
      & + label::before {
        background-color: $input-switch-active-bg;
      }

      & + label::after {
        transform: translate3d(($input-switch-width - $input-switch-handle-size) - ($input-switch-border-width * 2), -50%, 0);
      }
    }
  }

  label {
    user-select: none;
    display: inline-block;
    position: relative;
    margin-bottom: 0;

    &::before {
      content: "";
      position: absolute;
      display: block;
      height: $input-switch-height;
      width: $input-switch-width;
      top: 50%;
      left: 0;
      border-radius: $input-switch-border-radius;
      background-color: $input-switch-bg;
      transition: background-color .2s cubic-bezier(.24,0,.5,1);
      transform: translate3d(0, -50%, 0);
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      height: $input-switch-handle-size;
      width: $input-switch-handle-size;
      top: 50%;
      left: $input-switch-border-width;
      border-radius: $input-switch-border-radius;
      background: $input-switch-handle-bg;
      box-shadow: 0 0 0 1px hsla($input-switch-shadow-hue, 0%, 0%, 0.1),
        0 4px 0 0 hsla($input-switch-shadow-hue, 0%, 0%, .04),
        0 4px 9px hsla($input-switch-shadow-hue, 0%, 0%, .13),
        0 3px 3px hsla($input-switch-shadow-hue, 0%, 0%, .05);
      transition: .35s cubic-bezier(.54,1.60,.5,1);
      transform: translate3d(0, -50%, 0);
    }
  }
}

.form-control-switch-label {
  white-space: nowrap;
  margin-left: $input-switch-width;
  padding-left: $spacer;
}

@include media-breakpoint-up(lg) {
  .form-main {
    &:before {
      visibility: visible;
    }

    .form-control-label {
      color: #686868;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: $input-padding-y;
      padding-bottom: $input-padding-y;
      text-align: right;
      //text-align: right;
    }
  }
}
