.pagination {
  .page-item + .page-item .page-link {
    margin-left: 0.625rem;
  }

  .page-link {
    font-size: $font-size-sm;
    padding-bottom: $pagination-padding-y * 0.75;
  }

  .page-item {
    .page-link {
      @include border-radius($border-radius);
    }
  }
}