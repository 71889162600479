.popover {
  box-shadow: 0 8px 26px rgba(#3F4E5A, .14);
}

.popover-title {
  color: $popover-title-color;
  font-size: $popover-title-font-size;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  padding: 18px 18px 0;
}

.popover-content {
  color: $popover-content-color;
  padding: 10px 18px 18px;
}