.badge {
  text-transform: uppercase;
  letter-spacing: $letter-spacing-sm;
}

.badge-default {
  border: 1px solid $main-border;
  color: $theme-icon;
}

.tag-gold {
  @include tag-variant($badge-gold-bg);
  /*color: $badge-gold-color;*/
}

.tag-silver {
  @include tag-variant($badge-silver-bg);
  /*color: $badge-silver-color;*/
}