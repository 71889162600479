.notifications {
  position: fixed;
  width: 360px;
  z-index: 2060;
}

.notifications-top-right {
  top: 20px;
  right: 20px;
}

.notifications-top-center {
  top: 20px;
  margin: 0 0 0 -180px;
  left: 50%;
}

.notifications-top-left {
  top: 20px;
  left: 20px;
}

.notifications-bottom-right {
  bottom: 0;
  right: 20px;
}

.notifications-bottom-center {
  bottom: 0;
  margin: 0 0 0 -180px;
  left: 50%;
}

.notifications-bottom-left {
  bottom: 0;
  left: 20px;
}

.notification {
  opacity: 0;
}

.notification-icon {
  font-size: 1.25rem !important;
  float: left;
  margin-right: 1rem;
}

.notification-body {
  display: table;
}
