.hidden {
  display: none;
}

.col-flex {
  display: flex;
}

.col-justify-left {
  justify-content: flex-start;
}

.col-justify-center {
  justify-content: center;
}

.col-justify-right {
  justify-content: flex-end;
}

.col-align-top {
  align-items: flex-start;
}

.col-align-center {
  align-items: center;
}

.col-align-bottom {
  align-items: flex-end;
}


.number-value {
  color: #686868;
  font-size: $font-size-sm;
  font-family: $font-family-maison-mono;
  font-style: normal;
}

.font-mono {
  font-family: $font-family-maison-mono;
  font-style: normal;
}

.table-container {
  overflow-x: scroll
}

.sortable-link {
    color: #1F1F1F
}