.main-toolbar {
  padding: 24px 24px 0;
  position: relative;
  z-index: 500;

  &.headroom {
    will-change: transform;
  }

  &.headroom--pinned {
    transform: translateY(0%);
  }

  &.headroom--unpinned {
    position: fixed;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .form-group {
    margin-bottom: 0;
  }
}