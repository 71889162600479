body {
  visibility: hidden;
}

.wf-active,
.wf-inactive {
  body {
    visibility: visible;
  }
}

.wf-active {
  body,
  .popover {
    font-family: $font-family-maison;
  }
}
