header {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 24px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: $header-height;
  width: 100%;
  z-index: 510;

  .section-heading {
    text-transform: none;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-family: $font-family-maison-mono;
    font-weight: normal;
    display: inline-block;
    margin: 5px 55px;
  }
}

.header-left,
.header-center,
.header-right {
  display: flex;
  align-items: center;
}

.header-left,
.header-right {
  flex-shrink: 1;
}

.header-left {
  justify-content: flex-start;

  .badge + .badge {
    margin-left: ($spacer-x / 2);
  }
}

.header-right {
  justify-content: flex-end;

  .btn {
    font-size: $font-size-sm;
  }

  .badge + .badge {
    margin-right: ($spacer-x / 2);
  }
}

.header-center {
  flex: 3 0;
  justify-content: center;
}

.search {
  .btn {
    color: $brand-primary;
    border-width: 1px 1px 1px 0;
    &:hover {
    border-width: 1px 1px 1px 0;
    }
    &:focus {
    border-color: $main-border;
    }
  }
}

.header-report-alert {
  h5 {
    font-size: 1.125rem;
    margin-bottom: 0;

    .fa {
      margin-right: 13px;
    }
  }
}

.header-title {
  margin: 0;

  & + .text-muted {
    color: $main-border;
    display: inline-block;
    font-family: $font-family-maison-mono;
    font-size: $font-size-sm;
    margin-left: $spacer-x/2;
    top: -($spacer-y/2);
    position: relative;
  }
  
  & + .tag {
    margin-left: $spacer-x/2;
    position: relative;
    top: -0.5em;
  }
}

@include media-breakpoint-up(lg) {
  header {
    width: calc(100% - #{$side-menu-width});
    left: $side-menu-width;
  }
}