.typing-loader{
  width: .375rem;
  height: .375rem;
  border-radius: 50%;
  animation: typing 1s linear infinite alternate;
  margin: 2.875rem auto;
  position: relative;
  left: -.75rem;
}

@keyframes typing{
  0% {
    background-color: $theme-icon;
    box-shadow: .75rem 0 0 0 rgba($theme-icon, 0.2), 1.5rem 0 0 0 rgba($theme-icon, 0.2);
  }
  25% {
    background-color: rgba($theme-icon, 0.4);
    box-shadow: .75rem 0 0 0 $theme-icon, 1.5rem 0 0 0 rgba($theme-icon, 0.2);
  }
  75% {
    background-color: rgba($theme-icon, 0.4);
    box-shadow: .75rem 0 0 0 rgba($theme-icon, 0.2), 1.5rem 0 0 0 $theme-icon;
  }
}