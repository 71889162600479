.daterangepicker {
  .ranges {
    min-width: 196px;

    ul {
      width: auto;
    }

    li {
      background-color: #FAFAFA;
      border-color: #FAFAFA;
      color: $brand-primary;
      font-size: $font-size-sm;
      @include border-radius($border-radius);

      &.active {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: #fff;
      }
    }
  }

  td.active,
  td.active:hover {
    background-color: $brand-primary;
  }

  td.start-date {
    @include border-radius($input-border-radius 0 0 $input-border-radius);
  }

  td.end-date {
    @include border-radius(0 $input-border-radius $input-border-radius 0);
  }

  .input-mini {
    $padding-y: $input-padding-y / 2;
    $padding-left: $input-padding-x + $spacer;

    color: $input-color;
    border-color: $input-border-color;
    padding: $padding-y $input-padding-x $padding-y $padding-left;
    font-size: $font-size-base;
    line-height: $line-height;
    height: auto;
    @include border-radius($input-border-radius);

    &.active {
      border-color: $input-border-focus;
      @include border-radius($input-border-radius);
    }

  }
}