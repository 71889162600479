.calendar-event {
  .calendar-popover-content {
    display: none;
  }

  .calendar-event[data-event-id] {
    cursor: pointer;
  }

  &[data-type="day"],
  &[data-type="week"] {
    .calendar-event[data-paid="true"] {
      background-color: $brand-success;
    }
  }
}

.calendar-event-popover {
  max-width: inherit;
}

.calendar-events-preliminary-row {
  &[data-event-id] {
    cursor: pointer;
  }
}

.calendar-event-clean {
  background-color: transparent;
  border-width: 0;
  color: #DADFEA;
  padding: 0;
  position: absolute;
  right: 10px;
  bottom: 10px;

  @include hover-focus {
    color: #8C97B2;
  }

  &.active {
    color: #478800;

    @include hover-focus {
      color: #478800;
    }
  }
}

.calendar-resources-configurations {
  display: flex;
}

.calendar-resources-configuration {
  img {
    display: block;
    height: 32px;
  }

  & + .calendar-resources-configuration {
    margin-left: $spacer-x * 2;
  }
}

.calendar-event-checkout {
  background-color: #8C97B2;
}

.popover-content {
  .calendar-events-preliminary-row:last-child,
  .calendar-day-offers-row:last-child p {
    margin-bottom: 0;
  }
}
.form-group-event-period {
  .col-xs-2,.col-xs-4,.col-xs-1,.col-xs-3{
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
}






