.scopes {
  display: flex;
  margin-top: 24px;

  .nav-link + .nav-link {
    margin-left: .375rem;
  }

  .nav-link {
    display: block;
    color: #686868;
    font-size: $font-size-xs;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    padding: 0.25rem ($spacer-x / 2) 0.15rem;
  }

  .nav-link.active {
    background-color: $brand-primary;
    color: $white;
    position: relative;
    @include border-radius($border-radius);
  }
}