.side-menu {
  position: fixed;
  background-color: $gray-dark;
  padding: $header-height 0 $user-panel-height;
  color: $gray-lighter;
  top: 0;
  left: 0;
  transform: translate3d(#{-($side-menu-width + 10)}, 0, 0);
  z-index: $zindex-navbar-sticky;

  h6 {
    color: $gray;
    font-family: $font-family-maison-mono;
    font-size: $font-size-xs;
    padding: 0 40px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0 0 10px;
    position: relative;
    letter-spacing: 2px;

    &::before {
      background-color: $gray;
      content: '';
      height: 1px;
      width: 25px;
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
    }
  }

  ul {
    list-style-type: none;
    margin: 0 16px $spacer-y 0;
    padding: 0;

    li {
      width: 100%;
    }

    a {
      color: $gray-lighter;
      display: block;
      padding: 0 40px;
      line-height: 30px;
      font-size: $font-size-sm;
      width: 100%;
      position: relative;

      &:hover,
      &.active {
        text-decoration: none;
        background-color: $theme-hoverGrey
      }

      &.active {
        background-color: $brand-primary;
        color: $white;

        &::after {
          content: '';
          width: 0; 
          height: 0; 
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-right: 15px solid $gray-dark; 
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      &:hover {
        &:before {
          background-color: $brand-primary;
          border-radius: 3px;
          content: '';
          width: 6px;
          height: 6px;
          position: absolute;
          left: 21px;
          top: calc(50% - 3px);
        }
      }
    }
  }
}

.nav-links {
  padding-top: 24px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
}

.user-panel {
  background-color: $black;
  color: $gray-lighter;
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: $side-menu-width;
  padding: 0 16px 0 34px;
  height: $user-panel-height;
  z-index: 10;
}

.user-panel-right {
  flex-shrink: 1;
}

.user-panel-left {
  flex-grow: 1;
}

.user-panel-logout {
  $size: 18px;

  color: #4A4A4A;
  display: inline-block;
  font-size: $size;
  width: $size;
  height: $size;
  line-height: $size;
}

.is-logo {
  background-color: $white;
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 34px;
  height: $header-height;
  position: fixed;
  top: 0;
  left: 0;
  width: $side-menu-width;
  z-index: 10;

  a {
    display: inline-block;
  }
  
  img {
      width: 144px;
      height: 29px;
  }
}

@include media-breakpoint-up(lg) {
  .side-menu {
    position: relative;
    display: flex;
    flex: 0 1 $side-menu-width;
    flex-direction: column;
    z-index: inherit;
    transform: none;
  }
}
