.event-modal {
  .modal-dialog {
    // width: 680px;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 0 50px;
  }

  .modal-header {
    align-items: center;
    border-bottom-width: 0;
    display: flex;
    margin-bottom: 24px;
    min-height: 38px;
  }

  .modal-title {
    font-size: $font-size-h4;
    color: $gray-dark;
    flex: 1;
  }

  .modal-content {
    padding: 34px 0;
    border-width: 0;
    @include border-radius($border-radius);
  }

  .modal-footer {
    border: 0;
  }

  .tab-content {
    margin-bottom: 24px;
  }

  .tab-content--halls {
    min-height: 320px;
  }

  .tab-content--rooms {
    min-height: 326px;
  }

  .tab-content--catering,
  .tab-content--equipment {
    min-height: 264px;
  }

  .nav-tabs {
    .has-errors {
      color: $brand-danger;

      @include hover-focus-active {
        color: $brand-danger;
      }
    }
  }

  .btn-group-status {
    flex-shrink: 1;

    .btn {
      border-color: $input-border-color;
      color: $input-color-placeholder;
      font-size: $font-size-sm;
      margin-bottom: 0;

      &.active {
        color: $white;
      }
    }

    .btn-preliminary {
      &.active {
        background-color: $gray-dark;
        border-color: $gray-dark;
      }
    }

    .btn-confirmed {
      &.active {
        background-color: $brand-success;
        border-color: $brand-success;
      }
    }

    .is-disabled {
      display: none;
    }
  }

  .form-section-title {
    border-bottom: 1px solid $input-color-placeholder;
    font-size: $font-size-sm;
    font-weight: normal;
    padding-bottom: 12px;
    margin-top: $spacer;
    margin-bottom: $spacer;
  }

  .form-section-title + .form-group-linked-event {
    padding-top: 0;
  }

  .form-child,
  .form-child-fluid {
    //padding: 82px 0;
    padding: $spacer-y 0;
    position: relative;
    margin: 0 auto;
  }

  .form-child {
    max-width: 320px;
  }

  .form-child-header {
    position: relative;
    font-weight: normal;
    text-align: center;
    margin-bottom: 1.25rem;
  }

  .form-child-header-close {
    height: 32px;
    width: 32px;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    position: absolute;
    right: -48px;
    top: calc(50% - 16px);
    font-size: 24px;
    color: #8C97B2;
    cursor: pointer;
  }

  .form-group-water {
    background-color: #F8F9FA;
    padding: 24px;
    margin-bottom: ($spacer-y / 2);
  }

  .form-group-water-col {
    display: flex;
    align-items: center;

    .form-control {
      width: 76px;
    }

    label {
      color: $gray-dark;
      flex-grow: 1;
      margin-left: $spacer-x;
      margin-bottom: 0;
    }

    small {
      display: block;
      /*font-size: 12px;
      line-height: 16px;*/
      color: $gray-dark;
      text-transform: uppercase;
    }
  }

  .form-group-client-col {
    display: flex;
    align-items: flex-start;
    flex-shrink: 1;

    .selectize-input {
      /*max-width: 337px!important;*/
    }

    .btn {
      $btn-padding-y: $input-padding-y*2;

      border-color: $input-border-color;
      border-left-width: 0;
      color: $gray;
      padding: 0;
      height: 54px;
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: $font-size-sm;
      @include border-radius(0 $input-border-radius $input-border-radius 0);

      .fa {
        margin-right: 0;
      }
    }
  }

  .form-group-linked-event {
    border-bottom: 1px solid $main-border;
    font-size: $font-size-sm;
    padding-top: $spacer;
    padding-bottom: $spacer;

    .fa {
      color: $input-border-color;
      margin-right: $font-size-sm;
    }

    &.is-last {
      margin-bottom: $spacer * 2;
    }
  }

  .form-control-select-client {
    flex: 1;

    .selectize-input {
      @include border-radius($input-border-radius 0 0 $input-border-radius);
    }
  }


  .form-group-information {
    padding-top: $spacer;
    padding-bottom: $spacer;

    h6{
      font-size: $font-size-xs;
      color:#888;
    }

    &.is-last {
      margin-bottom: $spacer * 2;
    }
  }

  .form-control-select-client {
    flex: 1;

    .selectize-input {
      @include border-radius($input-border-radius 0 0 $input-border-radius);
    }
  }

  .unit-list {
    display: flex;
    flex-direction: column;

    .form-child-fluid  {
      margin: 0;

      &.is-edit {
        border-bottom: 1px solid $input-border-color;
      }

      .form-group:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .unit-list-row {
    color: $header-text;
    padding-top: $spacer-y;
    padding-bottom: $spacer-y;
    border-bottom: 1px solid $input-border-color;

    > div {
      display: flex;
      align-items: center;
    }

    .btn {
      background-color: transparent;
      border: 0 none;
      color: #B2B2B2;
      margin: 0;
      padding: 0;
      -webkit-appearance: none;

      @include hover-focus-active {
        color: #B2B2B2;
        text-decoration: none;
      }
    }

    .btn + .btn {
      margin-left: $spacer;
    }
  }

  .btn-delete-action {
    background-color: transparent;
    border: 0 none;
    color: #B2B2B2;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;

    @include hover-focus-active {
      color: #B2B2B2;
      text-decoration: none;
    }
  }

  .event-menu-nr {
    .btn {
      margin-left: 4px;
    }
  }

  .unit-list-number {
    background-color: $gray-dark;
    border-radius: 50%;
    display: inline-block;
    color: $white;
    width: 26px;
    height: 26px;
    line-height: 28px;
    text-align: center;
    font-size: $font-size-sm;
    font-weight: $badge-font-weight;
    white-space: nowrap;
    vertical-align: baseline;
  }

  .unit-list-comment {
    p {
      margin: 0;

      &:not(:empty) {
        padding-top: $spacer-y;
      }
    }
  }

  .tab-pane-configurations {
    .form-child {
      display: none;
    }

    &.has-form {
      .table-types,
      .btn-create {
        display: none;
      }

      .form-child {
        display: block;
      }
    }
  }

  .custom-checkbox {
    color: $theme-icon;
    display: flex;
    margin-bottom: 0;
    justify-content: flex-start;
    user-select: none;

    .fa {
      /*margin-right: $spacer-x;*/
    }

    input {
      display: none;
    }

    span {
      padding: $input-padding-y $input-padding-x;
      border: $input-btn-border-width solid $input-border-color;
      width: 100%;
      display: block;
      text-align: left;
      @include border-radius($input-border-radius);

      &.not-active {
        display: block;
      }

      &.is-active {
        display: none;
      }
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
    }

    input[type="checkbox"]:checked {
      background: red;

      + span.not-active {
        display: none;
      }

      ~ span.is-active {
        display: block;
        color: $brand-success;
        border-color: $brand-success;
      }
    }
  }

  .calendar-field {
    position: relative;

    &:after {
      content: '\f133';
      color: $theme-icon;
      font-family: $font-family-icons;
      position: absolute;
      top: $input-btn-border-width;
      right: 10px;
      padding: $input-padding-y .875rem;
      border-left: 1px solid $main-border;
      line-height: $input-line-height;
    }
  }

  //.calendar-input {
  //  &.is-opened {
  //    @include border-radius($input-border-radius $input-border-radius 0 0 );
  //  }
  //}

  .table-type {
    flex: 2 2 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin: 5px;
    text-align: center;
    position: relative;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    input:checked + .table-type-cell {
      border-color: $gray-dark;
      background-color: $white;
    }
  }

  .table-type-cell {
    background-color: #F8F9FA;
    border: 1px solid #F8F9FA;
    color: $gray-dark;
    padding: 18px 28px;
    display: flex;
    align-items: center;
    @include border-radius($border-radius);

    img {
      margin-right: 20px;
      object-fit: contain;
      object-position: 50% 50%;
      width: 30px;
      height: 30px;
    }
  }

  .tab-pane-equipment {
    label {
      display: flex;
      align-items: center;
      line-height: 20px;
      color: $header-text;

      span {
        display: inline-block;
        color: $theme-icon;
        padding-left: 5px;
      }
    }
  }

  .tab-pane-additional, .tab-pane-discounts {
    label {
      display: flex;
      align-items: center;
      line-height: 20px;
      color: $header-text;

      span {
        display: inline-block;
        color: $theme-icon;
        padding-left: 5px;
      }
    }
  }

  .modal-footer-nav {
    display: flex;
    align-items: center;
    height: 100%;

    .nav-item {
      margin-left: ($spacer-x * 2);
      &:first-child {
        margin-left: 0;
      }
    }

    .nav-link {
      color: $gray-dark;
      font-size: 14px;

      .fa {
        margin-right: 10px;
      }
    }
  }

  .eliminate-action-btn {
    background-color: $white;
    border: 0 none;
    color: $brand-danger;
    text-align: right;

    &:focus,
    &:hover,
    &:active {
      background-color: $white;
    }

    &:after {
      display: none;
    }
  }

  .eliminate-action-dropdown {
    .dropdown-menu {
      bottom: 100%;
      top: auto;
      left: auto;
      right: 0;
    }

    .dropdown-item {
      color: $brand-danger;
    }
  }

  #event-catering-list .col-justify-right .btn .fa:first-child {
    margin-right: 0;
  }
}
