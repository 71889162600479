.calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

/**
 * Header
 */
.calendar-header {
  align-items: center;
  display: flex;
  flex-shrink: 1;
  background: rgba(66, 66, 66, 1);
}

.calendar-header-main {
  display: flex;
  flex: 1;
  justify-content: center;
}

.calendar-header-types {
  flex-shrink: 1;
}

.calendar-body {
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  flex: 1;
  position: relative;
}

.calendar-loading {
  align-items: center;
  background-color: rgba(#000, .6);
  color: #fff;
  display: none;
  position: absolute;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
}

.calendar-loading-icon {
  width: 24px;
  height: 24px;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #fff;
  border-radius: 50%;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: '';
    background-color: #fff;
  }

  &::before {
    width: 8px;
    height: 2px;
    top: 11px;
    left: 11px;
    transform-origin: 1px 1px;
    animation: hrhand 8s linear infinite;
  }

  &::after {
    width: 10px;
    height: 2px;
    top: 11px;
    left: 11px;
    transform-origin: 1px 1px;
    animation: minhand 2s linear infinite;
  }
}

.calendar[data-type="month"] .calendar-body {
  flex-direction: column;
}


/**
 * Weekdays
 */
.calendar-weekdays {
  // background-color: #FAFAFA;
  border-bottom: 1px solid $gray-dark;
  display: flex;
}

.calendar-weekday {
  color: $gray-dark;
  flex: 1;
  font-size: $font-size-sm;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  // text-transform: uppercase;
}

.calendar-weekday + .calendar-weekday  {
  border-left: 1px solid #f1f1f1;
}


.calendar-grid {
  display: flex;
}

.calendar[data-type="week"] .calendar-grid-col + .calendar-grid-col {
  border-left: 1px solid #f1f1f1;
}

.calendar-grid-header {
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  height: 84px;
  align-items: center;
  justify-content: center;
}

.calendar-grid-header h4 {
  font-family: $font-family-maison-mono;
}

.calendar-grid-cell {
  display: flex;
  flex-direction: column;
  height: 114px;
}

.calendar-grid-cell + .calendar-grid-cell {
  border-top: 1px solid #f1f1f1;
}

.calendar[data-type="week"] .calendar-grid-col {
  width: calc(100% / 7);
}

/**
 * Day Offers
 */

.calendar-day-offers {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.calendar-day-offers-toggler {
  background: none;
  border: 0;
  color: #686868;
  display: block;
  padding: 0;
  line-height: 1;
  width: 14px;
  height: 14px;

  @include hover-focus-active {
    color: #686868;
    text-decoration: none;
  }
}

//.calendar-day-offers-toggler:hover + .calendar-day-offers-popover,
//.calendar-day-offers-popover:hover {
//  display: block;
//}

.calendar-day-offers-popover {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .3);
  display: none;
  position: absolute;
  padding: 16px 16px 0;
  top: 0;
  right: 0;
  z-index: 400;
}

.calendar-day-offers-row {
  font-size: 14px;
  white-space: nowrap;
}

.calendar[data-type="month"] {
  .calendar-day-offers {
    top: inherit;
    bottom: 8px;
  }

  .calendar-day-offers-popover {
    top: inherit;
    bottom: 0;
  }
}

/**
 * Timeline
 */
.calendar-timeline {
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.calendar-timeline-header {
  position: relative;
}

.calendar-timeline-header span {
  font-family: $font-family-maison-mono;
  font-size: $font-size-xs;
  text-transform: uppercase;
}

.calendar[data-type="day"] .calendar-timeline-header,
.calendar[data-type="day"] .calendar-timeline-cell {
  width: 148px;
}

.calendar[data-type="day"] .calendar-timeline-col + .calendar-timeline-col .calendar-timeline-header {
  position: relative;

  &:after {
    background-color: #f1f1f1;
    content: '';
    width: 1px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}

.calendar[data-type="day"] .calendar-timeline-header h4 {
  margin-bottom: 0;
  position: relative;
  top: -8px;
}

.calendar-timeline-col {
  position: relative;
  z-index: 1;
}

.calendar[data-type="week"] {
  .calendar-timeline-cell {
    position: relative;
  }

  .calendar-timeline-col {
    &.is-today {
      background-color: #f8f8f8;
    }
  }
}

/**
 * Slots
 */
.calendar-slots {
  display: flex;
  position: absolute;
  top: 56px;
  left: 0;
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
}

.calendar-slots-col {
  flex: 1;
}

.calendar-slots-col + .calendar-slots-col {
  border-left: 1px dashed #f1f1f1;
}

.calendar-slots-col + .calendar-slots-col.is-solid {
  border-left-style: solid;
}

.calendar-slot-minutes {
  color: #686868;
  font-size: $font-size-xs;
  font-family: $font-family-maison-mono;
  text-align: center;
}

/**
 * Events
 */
.calendar-events {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}

.calendar-event {
  background-color: $brand-green;
}

.calendar-event.preliminary-event{
  background-color: $brand-yellow;
}

.calendar-event.is-google{
  background-color: $gray-light;
}

.calendar[data-type="day"] .calendar-event {
  position: absolute;
}

.calendar[data-type="week"] .calendar-event {
  color: #fff;
  font-size: 12px;
  width: calc(100% - 34px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 4px 4px 0;
  padding: 4px 8px;
  @include border-radius(2px);
}

.calendar-event-content {
  color: #fff;
  padding: 12px;
}

.calendar-event-title {
  font-weight: 400;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.calendar-event-time {
  font-size: $font-size-sm;
  font-family: $font-family-maison-mono;
}

.calendar-event-create {
  background: $brand-primary;
  border: 0;
  border-radius: 2px;
  color: #fff;
  display: none;
  padding: 0;
  font-size: 20px;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  font-weight: normal;
}

.calendar[data-type="week"] .calendar-timeline-cell:hover,
.calendar-day:hover {
  .calendar-event-create {
    display: inline-block;
  }
}

.calendar-events-preliminary {
  position: absolute;
  right: 12px;
  bottom: 8px;
}

.calendar-events-preliminary-toggler {
  background: none;
  border: 0;
  color: #8C97B2;
  padding: 0;
  font-size: 8px;
  line-height: 1;

  @include hover-focus-active {
    color: #8C97B2;
    text-decoration: none;
  }
}

.calendar-events-preliminary-popover {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .3);
  display: none;
  position: absolute;
  padding: 16px 16px 8px;
  bottom: -8px;
  right: -8px;
  z-index: 400;
}

.calendar-events-preliminary-row {
  white-space: nowrap;
  margin-bottom: 8px;
}

//.calendar-events-preliminary-toggler:hover + .calendar-events-preliminary-popover,
//.calendar-events-preliminary-popover:hover {
//  display: block;
//}

/**
 * Time
 */
.calendar-time {
  position: absolute;
  z-index: 3;
  -webkit-user-select: none;
  user-select: none;
}

.calendar-time:before {
  background-color: $gray-dark;
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
}

.calendar-time-label {
  background-color: $gray-dark;
  border-radius: 2px;
  color: #fff;
  padding: 4px 0;
  position: absolute;
  font-size: $font-size-xs;
  top: -4px;
  left: -8px;
  width: 54px;
  text-align: center;
}

.calendar-time-label:after {
  content: '';
  height: 0;
  width: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $gray-dark;
  display: block;
  position: absolute;
  bottom: -6px;
  left: calc(50% - 6px);
}

/**
 * Resources
 */
.calendar-resources {
  border-right: 1px solid #f1f1f1;
  flex-shrink: 1;
  flex-direction: column;
}

.calendar-resources-header,
.calendar-resources-cell {
  /*width: 134px;*/
}

.calendar-resources-cell {
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
}

.calendar-resources-cell span {
  color: $gray-dark;
  font-size: $font-size-xs;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.calendar-resources-cell h5 {
  margin-bottom: 0;
}

/**
 * Month
 */
.calendar-month {
  display: flex;
  flex: 1;
  flex-direction: column;
  opacity: 0;
}

.calendar-month.is-new {
  -webkit-animation: fadeIn 1s ease-out;
  opacity: 1;
}

.calendar-month.is-in.is-next {
  animation: moveFromBottomFadeMonth .4s ease-out;
  opacity: 1;
}

.calendar-month.is-out.is-next {
  animation: moveToBottomFadeMonth .4s ease-in;
  opacity: 1;
}

.calendar-month.is-in.is-prev {
  animation: moveFromBottomFadeMonth .4s ease-out;
  opacity: 1;
}

.calendar-month.is-out.is-prev {
  animation: moveToBottomFadeMonth .4s ease-in;
  opacity: 1;
}

.calendar-week {
  display: flex;
  height: 100px;

  &.is-current {
    background-color: #f9f9f9;
  }
}

.calendar-week + .calendar-week {
  border-top: 1px solid #DADFE9;
}

.calendar-day {
  color: #697D8D;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-day + .calendar-day {
  border-left: 1px solid #F2F2F2;
}

.calendar-day-number {
  color: #686868;
  font-size: $font-size-sm;
  font-family: $font-family-maison-mono;
  position: absolute;
  top: 8px;
  left: 10px;
}

.calendar-day-events {
  color: #444C63;
  font-weight: 400;
  margin-bottom: 0;

  &[data-count="0"] {
    color: #F2F2F2;
  }
}

@keyframes minhand{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes hrhand{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Animations are cool!  */
@keyframes moveToBottomFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(30%);
  }
}

@keyframes moveFromBottomFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30%);
  }
}

@keyframes fadeIn  {
  from {
    opacity: 0;
  }
}