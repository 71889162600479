$auth-form-padding: $spacer * 2;

.auth {
  background-color: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-container {
  width: 456px;
}

.auth-logo {
  position: relative;
  text-align: center;
  margin-bottom: 40px;
  z-index: 50;

  a {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 188px;
  }

  svg {
    display: block;
  }
}

.auth-alerts {
  .alert {
    margin-bottom: -($spacer-y * 2);
    position: relative;
    @include border-radius(0);
  }

  p {
    margin-bottom: 0;
  }

  p + p {
    margin-top: $spacer-y;
  }
}

.auth-form {
  background-color: #fff;
  padding: ($auth-form-padding + $spacer) 3.125rem 3.125rem;
  @include border-radius($border-radius);

  .form-control-label {
    padding-left: 0;
  }

  .input-group-addon {
    background-color: #F8F9FA;

    a {
      color: $gray;
      font-size: $font-size-sm;

      @include hover-focus {
        color: $gray;
      }
    }
  }
}
