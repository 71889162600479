html,
body {
  height: 100%;
}

body {
  min-height: 100%;
}

.app-wrapper {
  min-height: 100%;
}

main {
  background-color: $main-border;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: $header-height;
}

.content {
  padding: $spacer;
  flex: 1;
  overflow: hidden;
}

.search-field {
  max-width: 230px;
  display: inline-block;
  font-size: initial;
  vertical-align: middle;
  width: 100%;
  padding: 0;

  input,
  .btn {
    border-color: $main-border;
    color: $theme-icon;
    display: inline-block;
    padding: 6px $spacer;
  }

  input {
    &[type="text"] {
      float: left;
      border-width: 1px 0px 1px 1px;
      &:focus {
        border-color: $main-border;
      }
    }
  }

  .btn {
    padding: 4px 15px;
    border-width: 1px 1px 1px 0;
    &:hover {
      border-width: 1px 1px 1px 0;
      background-color: $white;
    }
    &:focus {
      border-color: $main-border;
    }
  }
}

.calendar-control {
  display: inline-block;
  font-size: initial;
  text-align: center;
  vertical-align: middle;
  padding: 0 20px;
  width: 100%;
  > * {
    vertical-align: middle;
    font-size: initial;
  }
  span {
    font-family: $font-family-maison-mono;
    font-size: 20px;
    line-height: 24px;
    color: $gray-dark;
    display: inline-block;
    margin: 0 30px;
  }
  a {
    display: inline-block;
    border: 1px solid #D9D9D9;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    font-size: 18px;
    color: $gray-dark;
    .fa {
      line-height: 28px;
    }
  }
}

.flex-container {
  > div {
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
  > button.btn {
    max-width: 179px;
    width: 100%;
    background-color: $brand-primary;
    margin-left: 20px;
    padding: 4px 15px;
    color: $white;
    font-weight: 700;
    font-size: $font-size-sm;
    line-height: 16px;
  }
}

.table-filter {
  margin-bottom: 25px;
  .dropdown {
    max-width: 200px;
    width: 100%;
    .btn {
      text-align: left;
      width: 100%;
      padding: 4px 15px;
      border-color: $main-border;
      color: $theme-icon;
      &:after {
        position: absolute;
        right: 15px;
        top: 15px;
      }
      &:hover, &:focus {
        background-color: $white;
      }
    }
  }
}

.input-group {
  input,
  .btn {
    border-color: $input-border-color;
    color: $theme-icon;
  }
  .btn {
    padding: 6px 15px;
    &:hover, &:focus {
      background-color: $white;
    }
  }
  input {
    &[type="text"] {
      border-width: 1px 0px 1px 1px;
      &:focus {
        border-color: $input-border-color;
      }
    }
  }
}

&.table-action {
  width: 154px;
  li {
    white-space: nowrap;
  }
  a {
    letter-spacing: .6px;
  }
}

.table-bordered {
  th, td {
    border-color: $main-border;
  }
}

.data-table {
  background-color: $white;
  border-width: 0;
  margin-bottom: 0;
  font-size: $font-size-sm;
  td {
    font-size: $font-size-sm;
  }
  .row-order {
    float: right;
    i {
      font-size: 18px;
      color: $main-border;
      &.is-active {
        color: $theme-icon;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 18px;
    li {
      margin-bottom: 5px;
    }
  }

  .status-desc {
    li {
      color: $header-text;

    }
  }
  .student-list {
    a {
      color: $brand-primary;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .company-info {
    color: $header-text;
    b {
      display: inline-block;
      width: auto;
      margin-right: 5px;
    }
    li {
      margin-bottom: 5px;
    }
    a {
      color: $brand-primary;
    }
  }
  thead {
    background-color: $white;


    th {
      border: 1px solid $main-border;
      // border-bottom-color: $gray-dark;
      border-bottom-width: 0;
      border-right-width: 0;
      color: $gray-dark;
      font-weight: normal;
      position: relative;
      
      &::after {
        background-color: $gray-dark;
        display: block;
        content: '';
        height: 1px;
        right: 0;
        left: -1px;
        bottom: 0;
        position: absolute;
      }

      &:first-child {
        border-left-width: 0;
        left: 0;
        width: 5%;
      }

      h4 {
        color: $gray-dark;
        font-size: 1.125rem;
        font-weight: 700;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-bottom: 0;
      }
    }

    th + th {
      border-left-width: $border-width;
    }
  }
  th {
    padding: 17px 24px;
    font-size: $font-size-sm;
    line-height: 16px;

    &[scope="row"] {
      font-size: 14px;
      line-height: 18px;
      color: $theme-icon;
    }
  }
  tbody {
    th {
      padding: 20px 25px;
    }

    td {
      border-color: $main-border;
      border-right-width: 0;

      &:first-child {
        border-left-width: 0;
      }
    }

    td + td {
      border-left-width: 1px;
    }

    h4 {
      margin: 0 0 10px 0;
      line-height: 0;
      a {
        font-size: 20px;
        line-height: 24px;
        color: $brand-primary;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  td {
    padding: 20px 25px;
  }
}

.data-table-link {
  color: $input-color-placeholder;
  text-decoration: underline;

  @include hover {
    color: $input-color-placeholder;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    color: $input-color-placeholder;
  }
}

.rentable {
  text-align: center;

  thead {
    th {
      text-align: center;
      &:first-child {
        width: auto;
      }
    }

    .without-theme-border {
      th::after {
        content: initial;
      }

      th:first-child::after {
        content: '';
      }
    }
  }

  tbody {
    color: $gray-dark;

    td, th {
      padding: 30px;
      vertical-align: middle;
      font-weight: 300;
    }
    td, td span {
      font-size: 24px;
      line-height: 28px;
    }
    th {
      font-size: 20px;
      line-height: 24px;
    }
  }
  span {
    display: block;
    text-transform: uppercase;
    font-size: $font-size-xs;
  }
}

@include media-breakpoint-up(lg) {
  main {
    max-width: calc(100% - #{$side-menu-width});
  }

  .content {
    padding: 24px;
  }
}
