* {
  box-sizing: border-box;
  outline: none !important;
}

.flex-container {
  margin: 0;
  display: flex;
  flex-direction: row;
}

.theme-borders {
  border: 1px solid $main-border;
  border-radius: $border-radius;
}

// label {
//   margin-bottom: $spacer;
// }

.section-heading-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px $spacer-x;
  
  background-color: darken(#fff, 2%);
  padding: ($spacer / 2) $spacer-x;
  margin-top: $spacer-y * 2;
  
  
  .section-heading {
    flex-grow: 1;
  }
}

.section-heading {
  font-size: 1.6255rem;
  line-height: 2rem;
  position: relative;
  margin: 0;
  color: $gray-dark;
  /*text-align: center;*/
  
  font-size: 1.125rem;
  line-height: $line-height;
  font-weight: normal;
  color: $gray-dark;
}

.badge-custom {
  font: 700 10px/14px $proxima;
  padding: 1px 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: $letter-spacing-sm;
  &.gold {
    background-color: #F8E71C;
    color: #C1B200;
  }
  &.silver {
    background-color: #E9EDF0;
    color: $theme-icon;
  }
  &.regular {
    border: 1px solid $main-border;
    color: $theme-icon;
    background-color: $white;
  }
}

.rentable {
  &.table-striped {
    tbody tr:nth-of-type(even) {
      background-color: #FAFAFA;
    }

    tbody tr:nth-of-type(odd) {
      background-color: #fff;
    }
  }
}
#free_text_container, #form_free_text {
  margin-top: 20px;
}
#form_free_text {
  display:none;
}
