

// BS4 Vars
$enable-flex: true;
$border-radius: 3px;

$gray: #686868;
$gray-dark: #1F1F1F;
$gray-light: #818a91; 

$brand-success: #3AB1A6;
$brand-primary: #D01C45;
$brand-primary-hover: #B81339;
$brand-secondary-inset: #EEF4F9;
$brand-warning: #FF7004;
$brand-info: #1C8ED0;
$brand-danger: #D01C45;
$brand-inverse: $gray-dark !default;
$brand-yellow: #FFA82E;
$brand-green: #3AB1A6;


$badge-default-bg: #fff;
$badge-font-weight: 400;

$badge-gold-bg: #FDC50B;
$badge-gold-color: #C1B200;
$badge-silver-bg: #b7bfc5;
$badge-silver-color: #8C97B2;

$brand-danger: $brand-primary;

$grid-gutter-width-base: 1rem;

$font-size-base: 1rem;
$font-size-lg:   1.25rem;
$font-size-sm:   .875rem;
$font-size-xs:   .75rem;

//line-height
$line-height: 1.4;

// Modals

$modal-lg:                    900px !default;
$modal-md:                    680px !default;
$modal-sm:                    300px !default;

// Buttons
$btn-padding-y: .5rem;

// Forms
// #DADFEA
$input-color: #444C63;
$input-color-placeholder: #686868;
$input-border-color: #D9D9D9;
$input-border-focus: $gray-dark;
$input-padding-y: .5rem;
$input-group-addon-bg: #fff;


// $input-padding-x: .75rem;
// $input-padding-y: .250rem;

// Buttons
$btn-padding-y-lg: .8rem;
$btn-padding-x-sm: 1.25rem;
$btn-padding-y-sm: .500rem;

$btn-padding-x-lg: 1.5rem;
$btn-padding-y-lg: 1.116rem;

$btn-secondary-color: $gray;
$btn-secondary-bg: #F2F2F2;
$btn-secondary-border:  #F2F2F2;

$btn-border-radius-lg: $border-radius;

// Nav
$nav-tabs-border-color: #D9D9D9;
$nav-tabs-link-color: $input-color-placeholder;
$nav-tabs-link-decoration: none;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color;
$nav-disabled-link-color: lighten(#8C97B2, 10%);
$nav-tabs-active-link-hover-color: $gray-dark;
$nav-tabs-active-link-hover-bg: #fff;
$nav-tabs-active-link-hover-border-color: $nav-tabs-border-color;
$nav-tabs-active-link-line-color: $gray-dark;
$nav-tabs-active-link-line-height: 2px;

$letter-spacing-sm: .6px;

$popover-bg: #fff;
$popover-border-width: 0;
$popover-border-color: $popover-bg;
$popover-title-bg: $popover-bg;
$popover-arrow-width: 8px;
$popover-title-color: #8C97B2;
$popover-title-font-size: .75rem;
$popover-content-color: #444C63;

// Pagination

$pagination-padding-x:                .875rem !default;
$pagination-padding-y:                .5rem !default;
$pagination-padding-x-sm:             .75rem !default;
$pagination-padding-y-sm:             .275rem !default;
$pagination-padding-x-lg:             1.5rem !default;
$pagination-padding-y-lg:             .75rem !default;


$pagination-color:                     $gray !default;
$pagination-bg:                        transparent !default;
$pagination-border-width:              1px !default;
$pagination-border-color:              $input-border-color !default;

$pagination-hover-color:               $gray-dark !default;
$pagination-hover-bg:                  transparent !default;
$pagination-hover-border:              $input-border-color !default;

$pagination-active-color:              $gray-dark !default;
$pagination-active-bg:                 transparent !default;
$pagination-active-border:             $gray-dark !default;

$pagination-disabled-color:            $input-border-color !default;
$pagination-disabled-bg:               transparent !default;
$pagination-disabled-border:           $input-border-color !default;

// Tags

$tag-default-bg:            $gray;
$tag-primary-bg:            $brand-primary;
$tag-success-bg:            $brand-success;
$tag-info-bg:               $brand-info;
$tag-warning-bg:            $brand-warning;
$tag-danger-bg:             $brand-danger;

$tag-color:                 #fff;
$tag-link-hover-color:      #fff;
$tag-font-size:             .625rem;
$tag-font-weight:           normal;
$tag-padding-x:             .4em;
$tag-padding-y:             .25em;

$tag-pill-padding-x:        .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$tag-pill-border-radius:    10rem !default;

// theme colors

$theme-darkGrey: #333333;
$theme-hoverGrey: #202020;
$theme-olive: #F3E5AB;
$theme-shadow: #D9E4EC;
$white: #fff;
$black: #000;
$main-border: #f1f1f1;
$theme-icon: #8C97B2;
$header-text: #444C63;


// Header

$header-height: 70px;

// User Panel

$user-panel-height: 58px;

// Side Menu

$side-menu-width: 224px;


// fonts
$font-family-icons: FontAwesome;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$proxima: 'Proxima Nova', 'Helvetica', Arial, sans-serif;
$font-family-maison: MaisonNeue, $font-family-sans-serif;
$font-family-maison-mono: MaisonNeueMono, $font-family-sans-serif;

$font-family-base: $font-family-maison;
