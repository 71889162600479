.nav-tabs {
  .nav-item {
    + .nav-item {
      margin-left: 0;

      .nav-link {
        border-left-width: 0;
      }
    }

    &:first-child {
      .nav-link {
        @include border-radius($border-radius 0 0 0);
      }
    }

    &:last-child {
      .nav-link {
        @include border-radius(0 $border-radius 0 0);
      }
    }
  }

  .nav-link {
    font-size: $font-size-sm;
    color: $nav-tabs-link-color;
    border-color: $nav-tabs-border-color;
    text-decoration: $nav-tabs-link-decoration;
    position: relative;
    @include border-radius(0);

    &.disabled {
      @include plain-hover-focus {
        border-color: $nav-tabs-border-color;
      }
    }
  }

  .nav-link.active,
  .nav-item.open .nav-link {
    @include border-radius($border-radius $border-radius 0 0);

    &::after {
      content: '';
      width: 100%;
      height: $nav-tabs-active-link-line-height;
      background-color: $nav-tabs-active-link-line-color;
      left: 0;
      top: -$nav-tabs-active-link-line-height;
      position: absolute;
      @include border-radius($border-radius $border-radius 0 0);
    }
  }
}

.nav-tabs-bottom {
  border-top: 1px solid $nav-tabs-border-color;
  border-bottom: 0;

  .nav-item {
    // Make the list-items overlay the top border
    margin-top: -1px;

    &:first-child {
      .nav-link {
        @include border-radius(0 0 0 $border-radius);
      }
    }

    &:last-child {
      .nav-link {
        @include border-radius(0 0 $border-radius 0);
      }
    }
  }

  .nav-link {
    @include hover-focus {
      border-color: $nav-tabs-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color;
    }
  }

  .nav-link.active,
  .nav-item.open .nav-link {
    @include border-radius(0 0 $border-radius $border-radius);

    @include plain-hover-focus {
      color: $nav-tabs-active-link-hover-color;
      background-color: $nav-tabs-active-link-hover-bg;
      border-color: transparent $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-border-color;
    }

    &::after {
      top: inherit;
      bottom: -$nav-tabs-active-link-line-height;
      @include border-radius(0 0 $border-radius $border-radius);
    }
  }
}


.nav-tabs-fluid {
  display: flex;

  .nav-item {
    float: inherit;
    flex: 1;
  }

  .nav-link {
    text-align: center;
  }
}

.languageButtons, 
.languageButtonsHeader{
  max-width: 177px;
}


.lang-popover{
  padding:0px 9px;
  height:32px;
  vertical-align: middle;
  cursor: pointer;
  margin-right:11px;
  img{
    width: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-top:5px;
  }
  
}

.language-panel{
  background-color: $theme-darkGrey;
  width: $side-menu-width;
  z-index: 10;
  margin-left: -51px;
  

  &:after{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: $theme-darkGrey transparent transparent transparent;
    content: '';
    position: absolute;
    bottom: -7px;
    right: 53px;
  }

  .popover-content{
    color: $gray-lighter;
    display: block;
    padding: 12px 20px 12px 40px;
    line-height: 30px;
    font-size: $font-size-sm;
    width: 100%;
    position: relative;

    a,
    span{
      display: block;
      color: $gray-lighter;
      vertical-align: middle;
      text-decoration:none;
      &:hover{
        text-decoration:none;
      }
    }

    img{
      width: 14px;
      display: inline-block;
      vertical-align: middle;
      margin-top:-4px;
      margin-right:8px;
    }
  }
}
