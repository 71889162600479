.datepicker.dropdown-menu {
  border: $input-btn-border-width solid $input-border-focus;
  background: $input-bg;
  box-shadow: none;
  margin-top: -$input-btn-border-width;
  //@include border-radius(0 0 $input-border-radius $input-border-radius);

  @if $enable-shadows {
    @include box-shadow($input-box-shadow);
  } @else {
    box-shadow: none;
  }

  &::before,
  &::after {
    content: none;
  }
}

.datepicker.datepicker-orient-right.datepicker-orient-bottom {
  @include border-radius($input-border-radius 0 $input-border-radius $input-border-radius);
}

.datepicker.datepicker-orient-left.datepicker-orient-bottom {
  @include border-radius(0 $input-border-radius $input-border-radius $input-border-radius);
}