.br-theme-bars-movie {
  display: inline-block;

  .br-widget {
    white-space: nowrap;

    a {
      //background-color: $gray-lighter;
      //background-color:
      border: $border-width solid $brand-primary;
      display: inline-block;
      width: 60px;
      height: 8px;
      margin: 1px;

      &.br-active,
      &.br-selected {
        background-color: $brand-primary;
      }
    }

    .br-current-rating {
      text-align: center;
      display: block;
      padding-top: $spacer-y / 2;
      //padding: ($spacer-y / 2) 0 0;
      color: $brand-primary;
      font-weight: 400;
    }
  }

  .br-readonly {
    a {
      cursor: default;

      &.br-active,
      &.br-selected {
        background-color: #729bf8;
      }
    }

    .br-current-rating {
      color: #729bf8;
    }
  }
}

@media print {
  .br-theme-bars-movie .br-widget a {
    border: 1px solid #b3b3b3;
    background: white;
    box-sizing: border-box;
  }
  .br-theme-bars-movie .br-widget a.br-active,
  .br-theme-bars-movie .br-widget a.br-selected {
    border: 1px solid black;
    background: white;
  }
  .br-theme-bars-movie .br-widget .br-current-rating {
    color: black;
  }
}